<template>
  <main class="container">
    <ContentHeader
      :title="content_header.title"
      :subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header mb5">
        <div v-if="userCanCreateTemplate">
          <button
            type="button"
            class="
              button
              ml-auto
              page__body__header__button
              button--black
              text--capital
            "
            @click="toggleNavigationModal"
          >
            <span class="d-inline-flex mr-2">add template</span>
            <ion-icon name="add-outline" class="text--regular"></ion-icon>
          </button>
        </div>
      </div>

      <div class="mt5" style="width: 45%">
        <div class="form__item">
          <input
            type="text"
            class="input form__input form__input--lg"
            v-model="templateName"
            @input="performSearch"
            id="searchProduct"
            placeholder="Search for Template Name"
            style="height: 40px"
          />
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              event
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 25%"
            >
              body
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 13%"
            >
              target
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              type
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="this.templates.data.length">
          <tr v-for="(template, index) in this.templates.data" :key="index">
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                  style="word-wrap: break-word; padding-right: 25px"
                >
                  {{ template.templateName }}
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                  style="word-wrap: anywhere; padding-right: 25px"
                >
                  {{ template.templateEvent }}
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                  style="word-wrap: anywhere; padding-right: 25px"
                >
                  {{ template.templateBody }}
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                  style="padding-right: 25px"
                >
                  {{ template.templateTarget }}
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                  style="padding-right: 25px"
                >
                  {{ template.notificationType }}
                </span>
              </div>
            </td>
            <td style="padding-right: 0 !important;">
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="openEditTemplateModal(template)"
                    v-if="userCanEditTemplate"
                    >edit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    v-if="userCanDeleteTemplate"
                    @click="openDeleteTemplateModal(template)"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- Pagination -->
      <Pagination
        v-if="templates"
        :allItems="templates"
        :size="size"
        @update-created-task="changeLength"
        @change-page-index="changeCurrentPage"
      />

      <!-- Create Notification -->
      <createSmsTemplate
        :show="showCreateNotification"
        :populatedTemplate="populatedTemplate"
        :isEditing="isEditing"
        @successfully-save-template="saveTemplate"
        @error-saving-template="errorSavingTemplate"
        @close="closeTemplateModal"
      />

      <!-- Alert notification -->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :show="showDeletedTemplate"
      @close="closeDeletedTemplate"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Template</h5>
            <p>Please confirm that you want to delete this template</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeletedTemplate"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="loadingTemplate"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="deleteTemplate"
                v-else
              >
                confirm
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </main>
</template>
<script>
import { GET_ALL_TEMPLATES, DELETE_TEMPLATE } from "@/core/services/store/template.module";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
export default {
  name: "sms_template",
  data() {
    return {
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      content_header: {
        title: "Templates",
        subTitle: `Create and Manage your information disbursement pattern.`,
      },
      showCreateNotification: false,
      pages: 1,
      size: 20,
      populatedTemplate: {},
      isEditing: false,
      loading: false,
      showDeletedTemplate: false,
      deleteTemplateValue: "",
      loadingTemplate: false,
      templateName: "",
    };
  },
  components: {
    createSmsTemplate: () =>
      import(
        /* WebpackChunkName: "createNotification" */ "@/view/pages/sms_templates/sms/createSmsTemplate.vue"
      ),
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    DeleteModal,
  },
  computed: {
    ...mapState({
      templates: (state) => {
        return state.template.allTemplates?.data;
      },
      user: (state) => state.auth.user,
    }),
    userCanCreateTemplate() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Templates);
      const { permissionsList } = user;
      return permissionsList.CanCreateTemplate;
    },
    userCanEditTemplate() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Templates);
      const { permissionsList } = user;
      return permissionsList.CanEditTemplate;
    },
    userCanDeleteTemplate() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Templates);
      const { permissionsList } = user;
      return permissionsList.CanDeleteTemplate;
    },
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    closeTemplateModal() {
      this.populatedTemplate = {};
      this.isEditing = false;
      this.toggleNavigationModal();
    },
    toggleNavigationModal() {
      this.showCreateNotification = !this.showCreateNotification;
    },
    saveTemplate() {
      if (!this.isEditing) {
        this.populatedTemplate = {};
        this.alertSuccess("Template successfully created");
        this.getAllTemplates();
      } else {
        this.alertSuccess("Template successfully edited");
        this.getAllTemplates();
      }
    },
    deleteTemplate() {
      this.loadingTemplate = true;
      this.$store
        .dispatch(DELETE_TEMPLATE, { templateKey: this.deleteTemplateValue.templateKey })
        .then(() => {
          this.loadingTemplate = false;
          this.alertSuccess("Template was successfully deleted.");
          this.getAllTemplates();
          this.closeDeletedTemplate();
        });
    },
    errorSavingTemplate(value) {
      this.alertError(value);
    },
    performSearch() {
      this.getAllTemplates();
    },
    getAllTemplates() {
      this.loading = true;
      this.$store.dispatch(GET_ALL_TEMPLATES, {
        pageindex: this.pages,
        pagesize: this.size,
        templateName: this.templateName
      }).then(() => {
        this.loading = false;
      });
    },
    openEditTemplateModal(value) {
      this.populatedTemplate = value;
      this.isEditing = true;
      this.toggleNavigationModal();
    },
    openDeleteTemplateModal(value) {
      this.deleteTemplateValue = value;
      this.showDeletedTemplate = true;
    },
    closeDeletedTemplate() {
      this.deleteTemplateValue = "";
      this.showDeletedTemplate = false;
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.pages++;
        this.size = pageSize;
        this.getAllTemplates();
      } else if (pages === "previous") {
        this.pages--;
        this.size = pageSize;
        this.getAllTemplates();
      } else if (pages !== null) {
        this.parameters.pageIndex = pages;
        this.pages = pages;
        this.size = pageSize;
        this.getAllTemplates();
      }
    },

    changeLength(value) {
      this.size = value;
      this.getAllTemplates();
    },
  },
  created() {
    this.getAllTemplates();
  },
};
</script>
